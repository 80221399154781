import React from 'react'
import "./App.css";
import logo from "./logo.svg";
import instagramCap from "./images/instagramPerfil.png";
import "./App.css";





const InstagramImage = () => (
    <img
        className="instaP"
        src={instagramCap}
        alt="perfil instagram braid beautify"
        onClick={() =>
            window.open(
                "https://www.instagram.com/braids_beautify?igshid=Zzdtb3pzdDdhMTdy",
                "_blank"
            )
        }
    />
);




function Home() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h1>We appreciate your interest!</h1>
                <h3>
                    Our website is currently under development. Follow us on Instagram for
                    updates...!
                </h3>
                <a
                    className="App-link"
                    href="https://www.instagram.com/braids_beautify?igsh=Zzdtb3pzdDdhMTdy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Visit our Instagram...
                </a>

                <InstagramImage />
            </header>
        </div>
    )
}

export default Home